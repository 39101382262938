@charset "UTF-8";
// ==========================================================================
// Base – CSS animation
// ==========================================================================
@use "../configs/" as *;


.js-inView {
  opacity: 0;
}
.js-inViewAct {
  animation: inViewActKey .8s;
  animation-delay: .5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.455, .575, .090, 1.010);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;
  &.Delay {
    animation-delay: .28s;
  }
}
@keyframes inViewActKey {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}


.scrollMotion {
  animation: scrollMotionKey 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.78, .05, .04, 1.01);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;
}
@keyframes scrollMotionKey {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(80px);
  }
}
