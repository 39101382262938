@charset "UTF-8";
// ==========================================================================
// footer
// ==========================================================================
@use "../../configs/" as *;

.p_footerWrap {
  background: url("../images/section_curve_top.svg") no-repeat top center $color-dark;
  background-position: 0 -1px;
  background-size: 100%;
  color: $color-white;
  @include mqDF() {
    background: url("../images/section_curve_top_sp.svg") no-repeat top center $color-dark;
    background-position: 0 -1px;
    background-size: 100%;
    margin-top: 2px;
    padding: 70px 4% 16px;
  }
  padding: 70px 40px 16px;
  width: 100%;
  .p_footerHeader {
    display: flex;
    justify-content: space-between;
    .p_footerLogo {
      display: block;
    }
    .p_footerMail {
      background: url("../images/icon_w_mail.svg") no-repeat center;
      display: block;
      height: 40px;
      @include transition;
      width: 40px;
      a {
        display: block;
        height: 40px;
        width: 40px;
      }
      &:hover {
        @include mqDF() {
          opacity: 1;
        }
        opacity: .7;
      }
    }
  }

  .p_footerContent {
    align-items: end;
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    .p_footerAddress {
      @include mqDF() {
        width: 100%;
      }
      width: 36%;
      dt {
        font-size: 1.6rem;
        font-weight: bold;
      }
      dd {
        font-size: 1.4rem;
        margin-top: 16px;
        span {
          display: block;
        }
      }
    }
    .p_footerNavWrap {
      @include mqDF() {
        display: none;
      }
      width: 64%;
      .p_footerNav {
        display: flex;
        gap: 0 2%;
        &:last-child {
          margin-top: 16px;
        }
        .p_footerNavItem {
          width: 48%;
          .p_footerNavItem__main {
            display: block;
            font-size: 1.6rem;
            height: 48px;
            @include transition;
            position: relative;
            width: 100%;
            &:hover {
              @include mqDF() {
                opacity: 1;
              }
              opacity: .7;
              span {
                opacity: .7;
                transform: translateX(2px);
              }
            }
            &::after {
              background-color: $color-white;
              bottom: 0;
              content: "";
              height: 1px;
              left: 0;
              position: absolute;
              width: 100%;
            }
            a {
              align-items: center;
              display: flex;
              height: 100%;
              justify-content: space-between;
              width: 100%;
            }
            span {
              background: url("../images/link_w_arrow.svg") no-repeat center;
              display: block;
              height: 24px;
              @include transition;
              width: 26px;
            }
          }

          .p_footerNavItem__subList {
            display: flex;
            flex-wrap: wrap;
            gap: 28px 20px;
            padding: 26px 0;

            li {
              font-size: 1.2rem;
              @include transition;
              line-height: 1;
              padding-left: 2rem;
              position: relative;
              &:hover {
                @include mqDF() {
                  opacity: 1;
                }
                opacity: .7;
                &::before {
                  @include mqDF() {
                    transform: translateX(0px);
                  }
                  transform: translateX(5px);
                }
              }
              &::before {
                background-color: $color-white;
                content: "";
                height: 1px;
                @include transition;
                left: 0;
                position: absolute;
                top: 50%;
                width: 8px;
              }
            }
          }
        }
      }
    }
  }
  .p_footerNavPrivacy {
    // display: none;
    font-size: 1.2rem;
    @include mqDF() {
      display: block;
      font-size: 1.2rem;
      letter-spacing: .1em;
      margin-top: 32px;
      padding-right: 0;
      text-align: center;
      text-decoration: underline;
    }
    letter-spacing: .1em;
    margin-top: 32px;
    padding-right: 1.5%;
    text-align: right;
    text-decoration: underline;
    &:hover {
      opacity: .5;
    }
  }


  .p_footerCoyRight {
    color: $color-gray-a;
    font-size: 1.2rem;
    @include mqDF() {
      margin-top: 32px;
    }
    margin-top: 76px;
    text-align: center;
  }
}
