@charset "UTF-8";
// ==========================================================================
// header
// ==========================================================================
@use "../../configs/" as *;

body.fixed {
  @include mqDF() {
    min-height: 100vh; /* Fallback */
    min-height: calc(var(--vh, 1vh) * 100);
  }
}
html.fixed {
  @include mqDF() {
    min-height: 100vh; /* Fallback */
    min-height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
  }
}

.p_headerWrap {
  background: $color-white;
  height: 72px;
  @include mqDF() {
    height: 56px;
    padding: 0 4%;
  }
  padding: 0 40px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.p_headerInnerWrap {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
}
.p_headerLogo {
  display: block;
}
.p_headerNavToggle {
  display: none;
  height: 56px;
  @include mqDF() {
    display: block;
  }
  position: relative;
  width: 56px;
  &.Act {
    span {
      &:nth-child(1) {
        top: calc(50%);
      }
      &:nth-child(2) {
        top: calc(50%);
      }
    }
  }
  span {
    background: $color-main-b;
    display: block;
    height: 1px;
    @include transition;
    position: absolute;
    width: 56px;
    &:nth-child(1) {
      top: calc(50% - 3px);
    }
    &:nth-child(2) {
      top: calc(50% + 3px);
    }
  }
}
.p_headerNav {
  display: flex;
  @include mqDF() {
    background: $color-white;
    display: block;
    height: 100dvh;
    left: 0;
    opacity: 0;
    padding-top: 64px;
    pointer-events: none;
    position: fixed;
    top: 56px;
    transform: translateY(-100dvh);
    transition: all .5s cubic-bezier(1, .05, 0, 1.03);
    width: 100%;
    z-index: 99;
    &.Act {
      opacity: 1;
      pointer-events: all;
      transform: translateX(0);
    }
  }
}
.p_headerMenu {
  display: flex;
  gap: 0  56px;
  @include mqDF() {
    display: block;
  }
  .p_headerMenuItem {
    @include mqDF() {
      margin-bottom: 36px;//56px;
    }
    &:hover {
      a {
        &::after {
          bottom: -2px;
          @include mqDF() {
            opacity: 0;
          }
          opacity: 1;
        }
        .p_headerMenuItem__main {
          // color: $color-main-a;
          @include mqDF() {
            // color: $color-main-b;
          }
        }
        .p_headerMenuItem__sub {
          // color: $color-main-b;
          @include mqDF() {
            // color: $color-main-a;
          }
        }
      }
    }
    a {
      position: relative;
      &::after {
        background: $color-main-b;
        bottom: -4px;
        content: "";
        height: 1px;
        @include transition;
        left: 0;
        opacity: 0;
        position: absolute;
        width: 100%;
      }
      span {
        display: block;
        letter-spacing: .1em;
        text-align: center;
      }
      .p_headerMenuItem__main {
        color: $color-main-b;
        font-size: 1.6rem;
        font-weight: bold;
        @include transition;
        @include mqDF() {
          font-size: 3.2rem;
        }
        letter-spacing: .1em;
      }
      .p_headerMenuItem__sub {
        color: $color-main-a;
        font-size: 1.0rem;
        @include transition;
        @include mqDF() {
          font-size: 1.6rem;
        }
      }
    }
  }
}
.p_headerMail {
  background: url("../images/icon_mail.svg") no-repeat center;
  border: solid 1px $color-main-a;
  border-radius: 50%;
  display: block;
  height: 40px;
  @include transition;
  @include mqDF() {
    margin: 0 auto;
  }
  margin-left: 56px;
  width: 40px;
  &:hover {
    background: url("../images/icon_w_mail.svg") no-repeat center;
    background-size: 95%;
    @include mqDF() {
      background: url("../images/icon_mail.svg") no-repeat center;
      background-size: 100%;
      border: solid 1px $color-main-a;
    }
  }
  a {
    display: block;
    height: 100%;
    width: 100%;
  }
}
