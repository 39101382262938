@charset "UTF-8";
// ==========================================================================
// WP
// ==========================================================================
@use "../../configs/" as *;

.l-underLayerWrap {
  padding-bottom: 64px;
  .p_wpContent {
    padding-top: 64px;
  }
  .p_wpContentStatic {
    padding-top: 64px;
  }
}


.p_breadcrumbs {
  display: flex;
  gap: 0 32px;
  @include mqDF() {
    padding-left: 2%;
    padding-right: 2%;
  }
  padding: 0 40px;
  .p_breadcrumbsItem {
    color: $color-typo-b;
    font-size: 1.4rem;
    @include mqDF() {
      font-size: 1.3rem;
      white-space: nowrap;
      &:nth-child(n + 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    position: relative;
    &::after {
      background: $color-typo-b;
      content: "";
      height: 1px;
      position: absolute;
      right: -16px;
      top: 50%;
      transform: rotate(-45deg);
      transform-origin: center right;
      width: 6px;
    }
    &::before {
      background: $color-typo-b;
      content: "";
      height: 1px;
      position: absolute;
      right: -16px;
      top: 50%;
      transform: rotate(45deg);
      transform-origin: center right;
      width: 6px;
    }
    &:last-child {
      &::after,
      &::before {
        display: none;
      }
    }
    a {
      text-decoration: underline;
    }
  }
}

.p_wpContentMap {
  height: 0;
  margin-top: 16px;
  padding-top: 69%;
  position: relative;
  width: 100%;
}

.p_wpContentMap  iframe {
  filter: grayscale(1);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all .7s ease;
  width: 100%;
}



@mixin titles {
  .p_wpContentTitleWrap {
    display: flex;
    justify-content: center;
  }
  .m_sectionTitle {
    line-height: 1;
    text-align: center;
    h1,
    h2 {
      color: $color-main-a;
      font-size: 3.2rem;
      font-weight: bold;
      @include mqDF() {
      }
      letter-spacing: .1em;
    }
    span {
      display: block;
      height: 12px;
      margin: 10px auto 10px;
      width: 80px;
    }
    small {
      display: block;
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: .1em;
    }
  }
  //
  h2 {
    color: $color-typo-b;
    display: inline-block;
    font-size: 2.4rem;
    font-weight: bold;
    @include mqDF() {
      padding: 18px 24px;
    }
    letter-spacing: .1em;
    padding: 20px 24px;
    position: relative;
    text-align: center;
    &::before {
      background: $color-main-b;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &::after {
      background: $color-main-b;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  h3 {
    color: $color-typo-b;
    font-size: 2.4rem;
    font-weight: bold;
    padding-bottom: 7px;
    position: relative;
    &::before {
      background: $color-gray-c;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
    }
    &::after {
      background: $color-main-b;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      width: 40px;
    }
  }

  h4 {
    color: $color-typo-b;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: .1em;
    padding: 8px 0 8px 16px;
    position: relative;
    z-index: 2;
    &::before {
      background: $color-bg-b;
      bottom: 0;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
    &::after {
      background: $color-main-b;
      bottom: 0;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 4px;
      z-index: 2;
    }
  }

  h5 {
    border: solid 2px $color-main-b;
    border-radius: 16px 16px 4px 4px;
    color: $color-main-b;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: .1em;
    padding: 8px 14px 6px;
  }
  h6 {
    font-size: 2.4rem;
    font-weight: bold;
  }
}
.p_wpContentStatic {
  @include titles();
  @include mqDF() {
    padding-left: 2%;
    padding-right: 2%;
  }
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  .p_wpContentInner {
    margin-top: 64px;
  }
}


.p_wpContent {
  @include titles();
  @include mqDF() {
    padding-left: 2%;
    padding-right: 2%;
  }
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;


  ul {
    margin-top: 16px;
    li {
      font-size: 1.6rem;
      letter-spacing: .1em;
      line-height: 1.5;
      list-style: disc;
      margin-bottom: 6px;
      margin-left: 2rem;
      padding-left: -2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  strong {
    font-weight: bold;
  }
  .m_sectionTitle,
  .p_wpContentTitleWrap,
  .wp-block-columns,
  .wp-block-group {
    margin-bottom: 64px;
  }
  // div {
  //   margin-bottom: 64px;
  // }
  p {
    font-size: 1.6rem;
    @include mqDF() {
      // line-height: 1.6;
      // margin-top: 24px;
    }
    letter-spacing: .1em;
    line-height: 1.5;
    margin-top: 16px;
    a{
      &:hover{
        text-decoration: underline;
        @include mqDF() {
          text-decoration: none;
        }
      }
    }
  }

  button,
  .wp-block-button {
    border: solid 2px $color-main-b;
    border-radius: 30px;
    height: 48px;
    @include transition;
    margin-top: 16px;
    // width: 185px;
    min-width: 185px;
    &:hover {
      background: $color-main-b;
      a {
        color: $color-white;
        text-decoration: none;
        @include mqDF() {
          text-decoration: none;
        }
      }
    }
    a {
      align-items: center;
      color: $color-main-b;
      display: flex;
      font-size: 1.6rem;
      font-weight: bold;
      height: 100%;
      justify-content: center;
      padding: 0 32px;
      width: 100%;
    }
  }

  table {
    border: 1px solid $color-gray-a;
    border-collapse: collapse;
    color: $color-typo-b;
    margin-top: 16px;
    width: 100%;
    th {
      border: 1px solid $color-gray-a;
      border-collapse: collapse;
      font-size: 1.6rem;
      font-weight: normal;
      @include mqDF() {
        display: block;
        text-align: left;
        width: 100%;
      }
      letter-spacing: .1em;
      padding: 15px ;
      text-align: center;
    }
    td {
      border: 1px solid $color-gray-a;
      border-collapse: collapse;
      font-size: 1.6rem;
      @include mqDF() {
        display: block;
        width: 100%;
      }
      letter-spacing: .1em;
      padding: 15px ;
      &:nth-child(1) {
        @include mqDF() {
          text-align: left;
          width: 100%;
        }
        text-align: center;
        width: 24%;
      }
      a{
        &:hover{
          text-decoration: underline;
          @include mqDF() {
            text-decoration: none;
          }
        }
      }
    }
  }
  .wp-block-spacer {
    margin-bottom: 0 !important;
  }
  .wp-block-image {
    img {
      width: 100%;
    }
  }
  .wp-block-business-index-image {
    border-radius: 8px;
    overflow: hidden;
  }
  .wp-block-business-index-body {
    h6,
    p {
      @include mqDF() {
        text-align: center;
      }
    }
    .wp-block-button {
      @include mqDF() {
        margin-left: auto;
        margin-right: auto;
      }
      width: 185px;
    }
  }
}


/* pagination */
.pagination {
  width: 100%;
}

/* リンクの枠 */
.pagination .nav-links {
  display: flex;
  gap: 0 12px;
  justify-content: center;
}

/* ページの数字 */
.pagination .nav-links .page-numbers {
  display: block;
  font-size: 1.8rem;
  &:hover {
    text-decoration: underline;
  }
  &.current {
    color: $color-main-b;
    &:hover {
      text-decoration: none;
    }
  }
}

.pagination .fa-angle-right {
  display: block;
  height: 20px;
  position: relative;
  width: 20px;
  &::before {
    border-right: solid 2px $color-main-a;
    border-top: solid 2px $color-main-a;
    content: "";
    height: 10px;
    left: 0;
    position: absolute;
    top: 10px;
    transform: rotate(45deg);
    width: 10px;
  }
}
.pagination .fa-angle-left {
  display: block;
  height: 20px;
  position: relative;
  width: 20px;
  &::before {
    border-right: solid 2px $color-main-a;
    border-top: solid 2px $color-main-a;
    content: "";
    height: 10px;
    left: 0;
    position: absolute;
    top: 10px;
    transform: rotate(-135deg);
    width: 10px;
  }
}

// /* 前へ、次へボタン */
// .pagination .nav-links .prev,
// .pagination .nav-links .next{

// }

// /* ドット */
// .pagination .nav-links .dots{

// }

// /* 現在のページ */
// .pagination .nav-links .current{

// }

// /* ドットと現在位置以外にホバーした時 */
// .pagination .nav-links a:hover{

// }
.p_wpContentNotFoundWrap {
  min-height: 45vh;
}
.p_wpContentNotFound {
  font-size: 1.8rem;
  margin-top: 32px;
  text-align: center;
}
.p_wpContent .p_wpContentNotFoundButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
}

.p_wpContent .p_wpContentNewsBackButton {
  display: block;
  @include mqDF() {
    margin-top: 32px;
  }
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
}
