@charset "UTF-8";
// ==========================================================================
// Base – Global
// ==========================================================================
@use "../configs/variables" as *;

html {
  font-size: 62.5%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}
body {
  background-color: $color-bg;
  color: $color-black;
  font-family: $default-font-family;
  font-size: $default-font-size;
  line-height: $default-line-height;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
* {
  box-sizing: border-box;
}
