// @charset "UTF-8";
//  ==========================================================================
//  content
//  ==========================================================================
@use "../configs/" as *;

.l_wrap {
  height: 100%;
  @include mqDF() {
  }
  position: relative;
  width: 100%;
}

.l_contentWrap {
  box-sizing: border-box;
  @include mqDF() {
    padding-top: 56px;
  }
  padding-top: 78px;
  position: relative;
  width: 100%;
}
.l_content {
  @include mqDF() {
    padding-left: 4%;
    padding-right: 4%;
  }
  padding-left: 40px;
  padding-right: 40px;
}
