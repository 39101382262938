@charset "UTF-8";
// ==========================================================================
// TopPage
// ==========================================================================
@use "../configs/" as *;
.topPageSection {
  // padding-top: 3%;
  position: relative;
  &::before {
    background: url("../images/section_curve_top.svg") no-repeat;
    background-position: 0 -1px;
    background-size: 100%;
    content: "";
    height: 100%;
    @include mqDF() {
      background: url("../images/section_curve_top_sp.svg") no-repeat;
      background-position: 0 0;
      background-size: 100%;
    }
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
  &::after {
    background: url("../images/section_curve_bottom.svg") no-repeat;
    background-position: 0 100.5%;
    background-size: 100%;
    bottom: -1px;
    color: $color-white;
    content: "";
    height: 100%;
    @include mqDF() {
      background: url("../images/section_curve_bottom_sp.svg") no-repeat;
      background-position: 0 100%;
      background-size: 100%;
    }
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  &.NoBottom {
    &::after {
      display: none;
    }
  }
  &.NoTop {
    &::before {
      display: none;
    }
  }
}


.topPage__KV {
  align-items: center;
  display: flex;
  height: 90dvh;
  @include mqDF() {
    align-items: flex-start;
    height: 155dvw;
  }
  justify-content: center;
  margin-top: 26px;
  overflow: hidden;
  position: relative;
  width: 100%;
  .topPage__KVBg {
    background: url("../images/main_kv.jpg") no-repeat center bottom 0;
    background-size: cover;
    height: 100%;
    @include mqDF() {
      transform: scale(1.0);
    }
    left: 0;
    position: absolute;
    top: 0;
    // transform: scale(1.05);
    transform-origin: top center;
    width: 100%;
  }
  h2 {
    @include mqDF() {
      margin-top: 15dvh;
    }
    position: relative;
    z-index: 2;
    span {
      color: $color-white;
      display: block;
      font-size: 3.2rem;
      font-weight: 300;
    }
  }

  .topPage__KVTypo {
    opacity: 0;
    transform: translateY(10px);
    transition: all 1.5s cubic-bezier(.190, 1.000, .220, 1.000);
    &.Act {
      opacity: 1;
      transform: translateY(0px);
    }
    @for $index from 1 through 20 {
      &:nth-child(#{$index}) {
        transition-delay: $index/40 + s;
      }
    }
  }
}

.topPage__About {
  background: $color-white;
  @include mqDF() {
    padding-left: 0;
    padding-right: 0;
    padding-top: 4%;
  }
  padding-bottom: 76px;
  padding-top: 1%;
  p {
    font-size: 1.6rem;
    @include mqDF() {
      padding-left: 4%;
      padding-right: 4%;
    }
    line-height: 1.5;
    margin-bottom: 70px;
    text-align: center;
  }
}

.underPage__AboutNavWrap {
  margin-top: 64px;
}
.topPage__AboutNav,
.underPage__AboutNav {
  display: flex;
  flex-wrap: wrap;
  gap: 32px 2.5%;
  @include mqDF() {
    display: block;
    flex-wrap: unset;
    gap: unset;
  }
  margin-top: 24px;

  &.underPage__AboutNav {
    display: block;
  }
  .topPage__AboutNavItem,
  .underPage__AboutNavItem {
    @include mqDF() {
      aspect-ratio: 1/.6;
      width: 100%;
    }
    width: 48.75%;
    &.underPage__AboutNavItem {
      // aspect-ratio: 3/1;
      margin-top: 32px;
      width: 100%;
    }
    a {
      display: block;
      position: relative;
      width: 100%;
    }

    &:hover {
      .topPage__AboutNavItem__image,
      .underPage__AboutNavItem__image {
        @include mqDF() {
          transform: scale(1);
          &::after {
            opacity: 0;
          }
        }
        // transform: scale(.99);
        &::after {
          opacity: .1;
        }
      }
      .topPage__AboutNavItem__link,
      .underPage__AboutNavItem__link {
        background-color: $color-white;//rgba($color-dark, $alpha: .3);
        border: solid 1px $color-main-b;
        @include mqDF() {
          background-color: rgba($color-dark, $alpha: .1);
          border: solid 1px $color-white;
        }
        &__main {
          color: $color-main-b;
          @include mqDF() {
            color: $color-white;
          }
        }
        &__sub {
          color: $color-main-b;
          @include mqDF() {
            color: $color-white;
          }
        }
        &__arrow {
          background: url("../images/link_r_arrow_b.svg") no-repeat top center;
          background-size: cover;
          @include mqDF() {
            background: url("../images/link_w_arrow_b.svg") no-repeat top center;
            background-size: cover;
            transform: translateX(0px);
          }
          transform: translateX(5px);
        }
      }
    }
    .topPage__AboutNavItem__image,
    .underPage__AboutNavItem__image {
      border-radius: 16px;
      @include mqDF() {
        border-radius: 0;
      }
      @include transition;
      overflow: hidden;
      position: relative;
      width: 100%;
      &::after {
        background: $color-main-b;
        content: "";
        height: 100%;
        @include transition;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
      img {
        border-radius: 16px;
        @include mqDF() {
          border-radius: 0;
          height: 65vw;
          object-fit: cover;
        }
      }
    }
    .topPage__AboutNavItem__link,
    .underPage__AboutNavItem__link {
      align-items: center;
      aspect-ratio: 1/1;
      background-color: rgba($color-dark, $alpha: .1);
      border: solid 1px $color-white;
      border-radius: 50%;
      bottom: 16px;
      display: flex;
      @include transition;
      justify-content: center;
      position: absolute;
      right: 16px;
      width: 144px;
      z-index: 2;
      span {
        display: block;
        text-align: center;
      }
      &__main {
        color: $color-white;
        font-size: 1.6rem;
        font-weight: bold;
        @include transition;
        line-height: 1;
      }
      &__sub {
        color: $color-white;
        font-size: 1.2rem;
        font-weight: bold;
        @include transition;
        letter-spacing: .1em;
        line-height: 1;
        margin: 8px 0;
      }
      &__arrow {
        background: url("../images/link_w_arrow_b.svg") no-repeat top center;
        background-size: cover;
        height: 5px;
        @include transition;
        margin: 0 auto;
        width: 25px;
      }
    }
  }
}


.topPage__Business {
  background-color: $color-gray-b;
  @include mqDF() {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10%;
  }
  padding-bottom: calc(3% + 64px);
  padding-top: 3.8%;
  .topPage__BusinessNav {
    display: flex;
    flex-wrap: wrap;
    gap: 88px 3.3333%;
    @include mqDF() {
      gap: 40px 6%;
    }
    justify-content: center;
    margin-top: 58px;
    .topPage__BusinessNavItem {
      @include mqDF() {
        border-bottom: solid 1px $color-gray-a;
        padding-bottom: 16px;
        width: 43%;
      }
      width: 30%;
      &:hover {
        .topPage__BusinessNavItem__image {
          @include mqDF() {
            transform: scale(1);
            &::after {
              opacity: 0;
            }
          }
          transform: scale(.95);
          &::after {
            opacity: .1;
          }
        }
        .topPage__BusinessNavItem__title {
          @include mqDF() {
            opacity: 1;
          }
          opacity: .8;
        }
      }
      .topPage__BusinessNavItem__image {
        border-radius: 50%;
        @include transition;
        overflow: hidden;
        position: relative;
        &::after {
          background: $color-main-b;
          content: "";
          height: 100%;
          @include transition;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
        img {
          border-radius: 50%;
        }
      }
      .topPage__BusinessNavItem__title {
        font-size: 2.4rem;
        font-weight: bold;
        @include transition;
        @include mqDF() {
          font-size: 1.6rem;
        }
        letter-spacing: .1em;
        margin-top: 8px;
        text-align: center;
      }
    }
  }
}

.topPage__News,
.underPage__News {
  padding-bottom: 64px;
  padding-top: .6%;
  .topPage__NewsList {
    margin-top: 16px;
    .topPage__NewsListItem {
      position: relative;
      &:hover {
        &::before {
          @include mqDF() {
            width: 0;
          }
          width: 100%;
        }
        a {
          &::after {
            background: url("../images/link_r_arrow_b.svg") no-repeat top center;
            background-size: 100%;
            @include mqDF() {
              background: url("../images/link_b_arrow_b.svg") no-repeat top center;
              background-size: 100%;
              transform: translateX(0px);
            }
            transform: translateX(5px);
          }
        }
        .topPage__NewsListItem__time {
          color: $color-main-b;
          @include mqDF() {
            color: $color-main-a;
          }
        }
        .topPage__NewsListItem__title {
          color: $color-main-b;
          @include mqDF() {
            color: $color-main-a;
          }
        }
      }
      &::before {
        background-color: $color-main-b;
        bottom: 0;
        content: "";
        height: 1px;
        @include transition;
        left: 0;
        position: absolute;
        width: 0;
        z-index: 2;
      }
      &::after {
        background-color: $color-gray-a;
        bottom: 0;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
      }
      a {
        display: block;
        padding: 24px 0;
        position: relative;
        width: 100%;
        &::after {
          background: url("../images/link_b_arrow_b.svg") no-repeat top center;
          background-size: 100%;
          content: "";
          height: 5px;
          @include transition;
          margin: 0 auto;
          position: absolute;
          right: 0;
          top: 50%;
          width: 25px;
        }
      }
      .topPage__NewsListItem__time {
        font-size: 1.2rem;
        @include transition;
      }
      .topPage__NewsListItem__title {
        font-size: 1.6rem;
        font-weight: bold;
        @include transition;
        @include mqDF() {
          overflow: hidden;
          padding-right: 10%;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        padding-top: 6px;
      }
    }
  }
  .m_lineButton {
    margin: 24px auto 0;
  }
}

.topPage__CSR {
  @include mqDF() {
    padding-left: 0;
    padding-right: 0;
  }
  padding-left: 0;
  padding-right: 0;

  .topPage__CSRInner {
    align-items: center;
    aspect-ratio: 2.3/1;
    background: url("../images/csr_kv.jpg") no-repeat top center;
    background-size: cover;
    display: flex;
    height: 100%;
    @include mqDF() {
      aspect-ratio: 1/1.4;
    }
    justify-content: center;
  }
  .topPage__CSRCard {
    align-items: center;
    aspect-ratio: 1/1;
    background-color: rgba($color-dark, $alpha: .35);
    display: flex;
    @include mqDF() {
      min-width: 83%;
      width: 83%;
    }
    justify-content: center;
    min-width: 360px;
    padding: 28px;
    width: 25%;
    .topPage__CSRCard__head {
      text-align: center;
      h2 {
        color: $color-white;
        font-size: 3.2rem;
        font-weight: bold;
        letter-spacing: .1em;
        line-height: 1;
      }
      span {
        display: block;
        height: 12px;
        margin: 6px auto 12px;
        width: 80px;
      }
      div {
        color: $color-white;
        font-size: 1.2rem;
        font-weight: bold;
        letter-spacing: .1em;
      }
    }
    .topPage__CSRCard__read {
      color: $color-white;
      font-size: 1.4rem;
      font-weight: bold;
      letter-spacing: .1em;
      line-height: 1.5;
      margin: 24px 0 22px;
    }
    .m_lineButton {
      margin: 0 auto;
    }
  }
}
