@charset "UTF-8";
// ==========================================================================
// Base – Global Variables
// ==========================================================================
$default-font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif !default;
$default-font-size: 1.4rem;
$default-line-height: 1.5;

// Default Colours
$color-bg: #fff;
$color-white: #fff;
$color-black: #000;
$color-dark: #231815;
$color-typo-a: #231815;
$color-typo-b: #333;
$color-main-a: #231815;
$color-main-b: #d80c18;
$color-main-c: #f90;
$color-gray-a: #ccc;
$color-gray-b: #eee;
$color-gray-c: #999;
$color-bg-a: #fdf2fd;
$color-bg-b: #fdf2f3;


// $color-flow-a: #f3f7e6;
// $color-flow-b: #e5eecc;
// $color-flow-c: #d9e5b3;
// $color-flow-d: #cbdc99;
// $color-flow-e: #47b1ba;

// EEEEEE
