@charset "UTF-8";
// ==========================================================================
// form
// ==========================================================================
@use "../../configs/" as *;

.contactFromRead {
  @include mqDF() {
    text-align: left;
  }
  text-align: center;

  &.Fin {
    @include mqDF() {
      text-align: center;
    }
  }
}

.contactFromTop {
  @include mqDF() {
    // text-align: left;
  }
  margin-top: 24px;
  text-align: center;
  a {
    text-decoration: underline;
  }
}
.contactFromFin {
  display: flex;
  justify-content: center;
  min-height: 30dvh;
}
.contactFromBox {
  @include mqDF() {
    margin: 32px auto 0;
    width: 100%;
  }
  margin: 64px auto 0;
  width: 800px;
}
.contactFromOne {
  align-items: center;
  display: flex;
  @include mqDF() {
    display: block;
    margin-bottom: 26px;
  }
  margin-bottom: 32px;
  &.Head {
    align-items: flex-start;
  }
  dt {
    font-size: 1.6rem;
    font-weight: bold;
    @include mqDF() {
      // font-weight: 1.4rem;
      margin-bottom: 16px;
      padding-right: 0;
      width: 100%;
    }
    letter-spacing: .1em;
    line-height: 1.5;
    width: 20%;
    span {
      color: red;
      font-size: 1.2rem;
      padding-left: .5rem;
      vertical-align: top;
    }
  }
  dd {
    @include mqDF() {
      width: 100%;
    }
    // height: 36px;
    width: 80%;
    input {
      background: #fff;
      border: solid 1px $color-gray-a;
      border-radius: 4px;
      font-size: 1.6rem;
      padding: 15px 10px;
      width: 100%;
      &:focus {
        outline: 0;
      }
      &::placeholder {
        color: $color-gray-a;
      }
    }
    textarea {
      background: #fff;
      border: solid 1px $color-gray-a;
      border-radius: 4px;
      font-size: 1.6rem;
      height: 200px;
      padding: 15px 10px;
      resize: vertical; /* 縦方向のリサイズを許可 */
      width: 100%;
      &:focus {
        outline: 0;
      }
      &::placeholder {
        color: $color-gray-a;
      }
    }
  }
}

.contactFromPrivacyWrap {
  background: $color-gray-b;
  @include mqDF() {
    padding: 24px 24px;
  }
  margin-bottom: 32px;
  padding: 24px 24px;
  text-align: center;
  .contactFromPrivacy {
    color: #333;
    font-size: 1.4rem;
    letter-spacing: .1em;
    text-decoration: underline;
  }
  .contactFromPrivacyCheck {
    // align-items: center;
    // display: flex;
    font-size: 1.6rem;
    font-weight: bold;
    // justify-content: center;
    letter-spacing: .1em;
    margin-top: 16px;
    p {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .wpcf7-form-control-wrap {
      margin-top: -5px;
    }
    input[type="checkbox"] {
      appearance: none;
      border: 1px solid $color-main-a;
      height: 16px;
      margin-top: 2px;
      position: relative;
      vertical-align: -5px;
      width: 16px;
    }
    input[type="checkbox"]:checked:before {
      border-bottom: 2px solid $color-main-a;
      border-right: 2px solid $color-main-a;
      content: "";
      height: 10px;
      left: 4px;
      position: absolute;
      top: 1px;
      transform: rotate(50deg);
      width: 6px;
    }
    span {
      display: block;
      margin-left: 8px;
    }
  }
}
.contactSubmitButtonWrap {
  align-items: center;
  display: flex;
  justify-content: center;
  .wpcf7-spinner {
    display: none;
  }
}
.contactSendButtonWrap {
  p {
    display: flex;
    gap: 0 16px;
    @include mqDF() {
      display: block;
    }
    input {
      @include mqDF() {
        margin-bottom: 16px;
      }
    }
  }
}
.contactSubmitButton,
.wpcf7-previous,
.wpcf7-submit {
  background: $color-main-b;
  border-radius: 6px;
  color: $color-white;
  font-size: 1.8rem;
  font-weight: bold;
  height: 60px;
  letter-spacing: .1em;
  line-height: 60px;
  margin: 0 auto;
  text-align: center;
  width: 185px;

  &:disabled {
    background: $color-gray-a;
  }
}

.wpcf7-previous {
  background: $color-gray-a;
}
.wpcf7-response-output {
  border: solid 1px $color-main-b !important;
}
