@charset "UTF-8";
// ==========================================================================
// title
// ==========================================================================
@use "../../configs/" as *;

.m_sectionTitle {
  line-height: 1;
  text-align: center;
  h1,
  h2 {
    color: $color-main-a;
    font-size: 3.2rem;
    font-weight: bold;
    @include mqDF() {
    }
    letter-spacing: .1em;
  }
  span {
    display: block;
    height: 12px;
    margin: 10px auto 10px;
    width: 80px;
  }
  small {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: .1em;
  }
}
