@charset "UTF-8";
// ==========================================================================
// button
// ==========================================================================
@use "../../configs/" as *;

.m_lineButton {
  border: solid 2px $color-main-b;
  border-radius: 30px;
  height: 53px;
  @include transition;
  width: 195px;
  a {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 0 32px;
    width: 100%;
  }
  p {
    color: $color-main-b;
    font-size: 1.6rem;
    font-weight: bold;
  }
  span {
    background: url("../images/link_r_arrow.svg") no-repeat top center;
    background-size: cover;
    display: block;
    height: 5px;
    @include transition;
    width: 26px;
  }

  &:hover {
    background: $color-main-b;
    // border: solid 2px $color-white;
    @include mqDF() {
      background: none;
    }
    p {
      color: $color-white;
      @include mqDF() {
        color: $color-main-b;
      }
    }
    span {
      background: url("../images/link_w_arrow.svg") no-repeat top center;
      background-size: cover;
      @include mqDF() {
        background: url("../images/link_r_arrow.svg") no-repeat top center;
        background-size: cover;
        transform: translateX(0px);
      }
      transform: translateX(10px);
    }
  }
  &.White {
    border: solid 2px $color-white;
    p {
      color: $color-white;
    }
    span {
      background: url("../images/link_w_arrow.svg") no-repeat top center;
    }

    &:hover {
      background: $color-white;
      border: solid 2px $color-main-b;
      @include mqDF() {
        background: none;
        border: solid 2px $color-white;
      }
      p {
        color: $color-main-b;
        @include mqDF() {
          color: $color-white;
        }
      }
      span {
        background: url("../images/link_r_arrow.svg") no-repeat top center;
        background-size: cover;
        @include mqDF() {
          background: url("../images/link_w_arrow.svg") no-repeat top center;
          transform: translateX(0px);
        }
        transform: translateX(10px);
      }
    }
  }
}
// .m_faqButton {
//   background: $color-main-c;
//   border: solid 1px $color-main-c;
//   border-radius: 30px;
//   height: 53px;
//   @include transition;
//   width: 172px;
//   a {
//     display: block;
//     height: 100%;
//     text-align: center;
//     width: 100%;
//     span {
//       align-items: center;
//       color: $color-white;
//       display: flex;
//       font-weight: 700;
//       @include transition;
//       justify-content: center;
//       line-height: 52px;
//       position: relative;
//       text-align: center;
//       &::after {
//         background: url("../images/icon_button_arrow_1.svg") no-repeat right center;
//         background-size: 14px;
//         content: "";
//         display: block;
//         height: 14px;
//         @include transition;
//         margin-top: 1px;
//         padding-left: 24px;
//         width: 14px;
//       }
//     }
//   }

//   &:hover {
//     background: $color-white;
//     @include mqDF() {
//       background: $color-main-c;
//     }
//   }
// }
